import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useMobileFiltersModalStyles } from './hooks'

import {
  MaximumStartingPriceFilter,
  SpecialisationFilters,
  SpecialOfferFilters
} from '../FiltersModal/components'

import {
  clearFilterOptions,
  clearSortingOptions,
  fetchNumberOfPotentiallyRelevantListings,
  fetchPractitionerListings,
  selectFilterOptions,
  selectPotentiallyRelevantListingsNumber,
  selectSortOption,
  setFilterOptions,
  setSortOption
} from '../../../../../../../containers/mui/SearchPage/duck'
import { Button } from '../../../../../Button'
import { Icon } from '../../../../../Icon'

export const MobileFiltersModal = ({ isOpen, onClose }) => {
  const { classes } = useMobileFiltersModalStyles()
  const filterOptions = useSelector(selectFilterOptions)
  const dispatch = useDispatch()
  const sortBy = useSelector(selectSortOption)
  const potentiallyRelevantListingsNumber = useSelector(selectPotentiallyRelevantListingsNumber)
  const initialFilters = useRef(filterOptions)

  useEffect(() => {
    if (isOpen) {
      initialFilters.current = filterOptions
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchNumberOfPotentiallyRelevantListings())
    }
  }, [isOpen, filterOptions])

  const sortByChangeHandler = (event) => {
    const { value } = event.target
    dispatch(setSortOption(value))
  }

  const clearAllClickHandler = () => {
    dispatch(clearFilterOptions())
    dispatch(clearSortingOptions())
    onClose()
    dispatch(fetchPractitionerListings())
  }

  const showPractitionersClickHandler = () => {
    dispatch(fetchPractitionerListings())
    onClose()
  }

  const closeHandler = () => {
    dispatch(setFilterOptions(initialFilters.current))
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={closeHandler} fullScreen scroll="paper">
      <DialogTitle>
        <Box className={classes.headerContainer}>
          <Icon icon={faArrowLeft} className={classes.leftArrowIcon} onClick={closeHandler} />
          <Typography className={classes.headerText}>Filter and sort</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box className={classes.sortByContainer}>
          <Typography className={classes.sortByHeaderText}>Sort by</Typography>
          <RadioGroup value={sortBy} onChange={sortByChangeHandler}>
            <FormControlLabel value="recommended" control={<Radio />} label="Recommended" />
            <FormControlLabel value="topRated" control={<Radio />} label="Top-rated" />
            <FormControlLabel value="nearest" control={<Radio />} label="Nearest" />
          </RadioGroup>
        </Box>
        <SpecialOfferFilters />
        {/* <ExperienceFilters />
        <AreaFilters /> */}
        <SpecialisationFilters />
        <MaximumStartingPriceFilter />
      </DialogContent>
      <DialogActions style={{ marginBottom: '1rem', padding: '1rem 2rem' }}>
        <Box className={classes.buttonsContainer}>
          <Button uiType="secondary" className={classes.button} onClick={clearAllClickHandler}>
            Clear all
          </Button>
          <Button
            uiType="primary"
            className={classes.button}
            onClick={showPractitionersClickHandler}
          >
            {`Show ${potentiallyRelevantListingsNumber} ${potentiallyRelevantListingsNumber > 1 ? 'practitioners' : 'practitioner'}`}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
