export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('Analytics page view:', url)
  }
}

// Google Analytics 4 (GA4) using gtag.js script, which is included in util/includeScripts.js
export class GoogleAnalyticsHandler {
  trackPageView(canonicalPath, previousPath) {
    // GA4 property. Manually send page_view events
    // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    // Note 1: You should turn "Enhanced measurement" off.
    //         It attaches own listeners to elements and that breaks in-app navigation.
    // Note 2: If previousPath is null (just after page load), gtag script sends page_view event automatically.
    //         Only in-app navigation needs to be sent manually from SPA.
    // Note 3: Timeout is needed because gtag script picks up <title>,
    //         and location change event happens before initial rendering.
    if (previousPath && window) {
      window.setTimeout(() => {
        // Send to GTM
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'page_view',
          page_path: canonicalPath
        })

        // if (window.gtag) {
        //   // Send directly to GA4
        //   window.gtag('event', 'page_view', {
        //     page_path: canonicalPath
        //   })
        // }
      }, 300)
    }
  }
}

export const AnalyticsEvent = {
  // Booking Events
  bookingRequestTapped: 'booking_request_tapped',
  bookingRequestVoucherClaimed: 'booking_request_voucher_claimed',

  bpConsultationChosen: 'bp_consultation_chosen', // When a client choses a consultation option
  bpTimeSlotChosen: 'bp_time_slot_chosen', // When a client choses a time slot
  bpBookingRequested: 'bp_booking_requested', // When a client submits their booking request
  bpCancelBooking: 'bp_cancel_booking', // When a client cancels their booking
  bpRescheduleBooking: 'bp_reschedule_booking', // When a client reschedules their booking

  bpHcpAcceptBooking: 'bp_hcp_accept_booking', // When a HCP accepts a booking
  bpHcpRejectBooking: 'bp_hcp_reject_booking', // When a HCP rejects a booking
  bpHcpConfirmBooking: 'bp_hcp_confirm_booking', // When a HCP confirms a booking
  bpHcpCancelBooking: 'bp_hcp_cancel_booking', // When a HCP cancels a booking
  bpHcpModifyBooking: 'bp_hcp_modify_booking', // When a HCP modifies a booking
  bpHcpMarkNoShow: 'bp_hcp_mark_no_show', // When a HCP marks a booking as no show
  bpHcpRefundNoShow: 'bp_hcp_refund_no_show', // When a HCP refunds a no show fee

  instagramTapped: 'instagram_tapped',
  visitWebsiteTapped: 'visit_website_tapped',
  visitClinicTapped: 'visit_clinic_tapped',
  shareButtonTapped: 'share_button_tapped',
  requestToModelTapped: 'request_to_model_tapped',
  writeReviewTapped: 'write_review_tapped',
  writeReviewSubmitted: 'write_review_submitted',
  consultationSearchTapped: 'consultation_search_tapped',
  postConcernSearchTapped: 'post_concern_search_tapped',
  replyConsultRequestTapped: 'reply_consult_request_tapped',
  replyConsultRequestSubmitted: 'reply_consult_request_submitted',
  consultationProfileTapped: 'consultation_profile_tapped',
  consultationLearnMoreTapped: 'consultation_learn_more_tapped',
  consultationBookTapped: 'consultation_book_tapped',
  bannerLearnMoreTapped: 'banner_learn_more_tapped',
  bannerPlaybookLibraryTapped: 'banner_playbook_library_tapped',
  practitionerConsultationTapped: 'practitioner_consultation_tapped',
  onlineConciergeTapped: 'online_concierge_tapped',
  voucherViewed: 'voucher_viewed',
  errorShown: 'error_shown',

  bookingRequestVoucherOpened: 'booking_request_voucher_opened', // ARCHIVED
  contactRequestForConsultTapped: 'contact_request_for_consult_tapped' // ARCHIVED
}

export const trackEvent = (eventName, eventParams) => {
  if (process.env.REACT_APP_ENV === 'development') {
    console.log('Track Event:', eventName, eventParams)
  }

  if (window && window.location.href.includes('app.seestella.com.au')) {
    // if (window.gtag) {
    //   window.gtag('event', eventName, eventParams)
    // }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: eventName,
      ...eventParams
    })
  }
}
