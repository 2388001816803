// ================ ACTION TYPES ================ //

import {
  getClientBookings,
  getClientDashboard,
  getClientRewards,
  getClientSkinReportPdfUrl,
  joinSkinChatWaitlist
} from '../../../util/api'
import { fetchPractitionerListings } from '../SearchPage/duck'

export const FETCH_DASHBOARD_REQUEST = 'app/ClientDashboardPage/FETCH_DASHBOARD_REQUEST'
export const FETCH_DASHBOARD_SUCCESS = 'app/ClientDashboardPage/FETCH_DASHBOARD_SUCCESS'
export const FETCH_DASHBOARD_ERROR = 'app/ClientDashboardPage/FETCH_DASHBOARD_ERROR'

export const JOIN_SKIN_CHAT_WAITLIST_REQUEST =
  'app/ClientDashboardPage/JOIN_SKIN_CHAT_WAITLIST_REQUEST'
export const JOIN_SKIN_CHAT_WAITLIST_SUCCESS =
  'app/ClientDashboardPage/JOIN_SKIN_CHAT_WAITLIST_SUCCESS'
export const JOIN_SKIN_CHAT_WAITLIST_ERROR = 'app/ClientDashboardPage/JOIN_SKIN_CHAT_WAITLIST_ERROR'

export const FETCH_BOOKINGS_REQUEST = 'app/ClientDashboardPage/FETCH_BOOKINGS_REQUEST'
export const FETCH_BOOKINGS_SUCCESS = 'app/ClientDashboardPage/FETCH_BOOKINGS_SUCCESS'
export const FETCH_BOOKINGS_ERROR = 'app/ClientDashboardPage/FETCH_BOOKINGS_ERROR'

export const FETCH_SKIN_REPORT_PDF_URL_REQUEST =
  'app/ClientDashboardPage/FETCH_SKIN_REPORT_PDF_URL_REQUEST'
export const FETCH_SKIN_REPORT_PDF_URL_SUCCESS =
  'app/ClientDashboardPage/FETCH_SKIN_REPORT_PDF_URL_SUCCESS'
export const FETCH_SKIN_REPORT_PDF_URL_ERROR =
  'app/ClientDashboardPage/FETCH_SKIN_REPORT_PDF_URL_ERROR'

export const FETCH_CLIENT_REWARDS_REQUEST = 'app/ClientDashboardPage/FETCH_CLIENT_REWARDS_REQUEST'
export const FETCH_CLIENT_REWARDS_SUCCESS = 'app/ClientDashboardPage/FETCH_CLIENT_REWARDS_SUCCESS'
export const FETCH_CLIENT_REWARDS_ERROR = 'app/ClientDashboardPage/FETCH_CLIENT_REWARDS_ERROR'

export const CLEAR_DASHBOARD = 'app/ClientDashboardPage/CLEAR_DASHBOARD'

// ================ REDUCER ================ //

const initialState = {
  data: {
    bookings: []
  },
  fetchInProgress: false,
  fetchError: null,
  joinSkinChatWaitlistInProgress: false,
  joinSkinChatWaitlistError: null,
  fetchBookingsInProgress: false,
  fetchBookingsError: null,
  fetchSkinReportPdfUrlInProgress: false,
  fetchSkinReportPdfUrlError: null,
  fetchRewardsInProgress: false,
  fetchRewardsError: null
}

const clientDashboardReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_DASHBOARD_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchError: null
      }
    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        fetchInProgress: false,
        data: payload
      }
    case FETCH_DASHBOARD_ERROR:
      return {
        ...state,
        fetchInProgress: false,
        fetchError: payload
      }
    case JOIN_SKIN_CHAT_WAITLIST_REQUEST:
      return {
        ...state,
        joinSkinChatWaitlistInProgress: true,
        joinSkinChatWaitlistError: null
      }
    case JOIN_SKIN_CHAT_WAITLIST_SUCCESS:
      return {
        ...state,
        joinSkinChatWaitlistInProgress: false,
        data: {
          ...state.data,
          dashboard: {
            ...state.data.dashboard,
            settings: payload
          }
        }
      }
    case JOIN_SKIN_CHAT_WAITLIST_ERROR:
      return {
        ...state,
        joinSkinChatWaitlistInProgress: false,
        joinSkinChatWaitlistError: payload
      }

    case FETCH_BOOKINGS_REQUEST:
      return {
        ...state,
        fetchBookingsInProgress: true,
        fetchBookingsError: null
      }
    case FETCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        fetchBookingsInProgress: false,
        data: {
          ...state.data,
          dashboard: {
            ...state.data.dashboard,
            bookings: payload
          }
        }
      }
    case FETCH_BOOKINGS_ERROR:
      return {
        ...state,
        fetchBookingsInProgress: false,
        fetchBookingsError: payload
      }

    case FETCH_SKIN_REPORT_PDF_URL_REQUEST:
      return {
        ...state,
        fetchSkinReportPdfUrlInProgress: true,
        fetchSkinReportPdfUrlError: null
      }
    case FETCH_SKIN_REPORT_PDF_URL_SUCCESS:
      return {
        ...state,
        fetchSkinReportPdfUrlInProgress: false,
        data: {
          ...state.data,
          dashboard: {
            ...state.data.dashboard,
            skinReport: {
              ...state.data.dashboard.skinReport,
              pdfUrl: payload
            }
          }
        }
      }
    case FETCH_SKIN_REPORT_PDF_URL_ERROR:
      return {
        ...state,
        fetchSkinReportPdfUrlInProgress: false,
        fetchSkinReportPdfUrlError: payload
      }

    case FETCH_CLIENT_REWARDS_REQUEST:
      return {
        ...state,
        fetchRewardsInProgress: true,
        fetchRewardsError: null
      }
    case FETCH_CLIENT_REWARDS_SUCCESS:
      return {
        ...state,
        fetchRewardsInProgress: false,
        data: {
          ...state.data,
          rewardState: payload?.rewardState,
          userRewards: payload?.userRewards
        }
      }
    case FETCH_CLIENT_REWARDS_ERROR:
      return {
        ...state,
        fetchRewardsInProgress: false,
        fetchRewardsError: payload
      }

    case CLEAR_DASHBOARD:
      return initialState
    default:
      return state
  }
}

export default clientDashboardReducer

// ================ ACTION CREATORS ================ //

export const fetchDashboardRequest = () => ({
  type: FETCH_DASHBOARD_REQUEST
})

export const fetchDashboardSuccess = (data) => ({
  type: FETCH_DASHBOARD_SUCCESS,
  payload: data
})

export const fetchDashboardError = (error) => ({
  type: FETCH_DASHBOARD_ERROR,
  payload: error
})

export const joinSkinChatWaitlistRequest = () => ({
  type: JOIN_SKIN_CHAT_WAITLIST_REQUEST
})

export const joinSkinChatWaitlistSuccess = (settings) => ({
  type: JOIN_SKIN_CHAT_WAITLIST_SUCCESS,
  payload: settings
})

export const joinSkinChatWaitlistError = (error) => ({
  type: JOIN_SKIN_CHAT_WAITLIST_ERROR,
  payload: error
})

export const fetchBookingsRequest = () => ({
  type: FETCH_BOOKINGS_REQUEST
})

export const fetchBookingsSuccess = (bookings) => ({
  type: FETCH_BOOKINGS_SUCCESS,
  payload: bookings
})

export const fetchBookingsError = (error) => ({
  type: FETCH_BOOKINGS_ERROR,
  payload: error
})

export const fetchSkinReportPdfUrlRequest = () => ({
  type: FETCH_SKIN_REPORT_PDF_URL_REQUEST
})

export const fetchSkinReportPdfUrlSuccess = (pdfUrl) => ({
  type: FETCH_SKIN_REPORT_PDF_URL_SUCCESS,
  payload: pdfUrl
})

export const fetchSkinReportPdfUrlError = (error) => ({
  type: FETCH_SKIN_REPORT_PDF_URL_ERROR,
  payload: error
})

export const fetchClientRewardsRequest = () => ({
  type: FETCH_CLIENT_REWARDS_REQUEST
})

export const fetchClientRewardsSuccess = (rewards) => ({
  type: FETCH_CLIENT_REWARDS_SUCCESS,
  payload: rewards
})

export const fetchClientRewardsError = (error) => ({
  type: FETCH_CLIENT_REWARDS_ERROR,
  payload: error
})

export const clearDashboard = () => ({
  type: CLEAR_DASHBOARD
})

// ================ SELECTORS ================ //

export const selectClientDashboard = (state) => state.ClientDashboardPage

export const selectDashboardData = (state) => selectClientDashboard(state).data
export const selectFetchInProgress = (state) => selectClientDashboard(state).fetchInProgress
export const selectFetchError = (state) => selectClientDashboard(state).fetchError

export const selectJoinSkinChatWaitlistInProgress = (state) =>
  selectClientDashboard(state).joinSkinChatWaitlistInProgress
export const selectJoinSkinChatWaitlistError = (state) =>
  selectClientDashboard(state).joinSkinChatWaitlistError

export const selectFetchBookingsInProgress = (state) =>
  selectClientDashboard(state).fetchBookingsInProgress
export const selectFetchBookingsError = (state) => selectClientDashboard(state).fetchBookingsError

export const selectFetchSkinReportPdfUrlInProgress = (state) =>
  selectClientDashboard(state).fetchSkinReportPdfUrlInProgress
export const selectFetchSkinReportPdfUrlError = (state) =>
  selectClientDashboard(state).fetchSkinReportPdfUrlError

export const selectFetchRewardsInProgress = (state) =>
  selectClientDashboard(state).fetchRewardsInProgress
export const selectFetchRewardsError = (state) => selectClientDashboard(state).fetchRewardsError

// ================ THUNKS ================ //

export const fetchClientDashboardThunk = () => async (dispatch, getState, sdk) => {
  dispatch(fetchDashboardRequest())

  try {
    const result = await getClientDashboard()
    if (result.response) {
      dispatch(fetchDashboardSuccess(result.response))
    } else {
      throw new Error('Error fetching dashboard')
    }
  } catch (error) {
    console.error('Error fetching client dashboard:', error)
    dispatch(fetchDashboardError(error))
  }
}

export const fetchClientBookingsThunk = () => async (dispatch, getState, sdk) => {
  dispatch(fetchBookingsRequest())

  try {
    const result = await getClientBookings()
    if (result.response) {
      dispatch(fetchBookingsSuccess(result.response))
    } else {
      throw new Error('Error fetching bookings')
    }
  } catch (error) {
    console.error('Error fetching client bookings:', error)
    dispatch(fetchBookingsError(error))
  }
}

export const fetchClientSkinReportPdfUrlThunk = () => async (dispatch, getState, sdk) => {
  dispatch(fetchSkinReportPdfUrlRequest())

  try {
    const result = await getClientSkinReportPdfUrl()
    if (result.response) {
      dispatch(fetchSkinReportPdfUrlSuccess(result.response))
    } else {
      throw new Error('Error fetching skin report pdf url')
    }
  } catch (error) {
    console.error('Error fetching client skin report pdf url:', error)
    dispatch(fetchSkinReportPdfUrlError(error))
  }
}

export const fetchClientRewardsThunk = () => async (dispatch, getState, sdk) => {
  dispatch(fetchClientRewardsRequest())

  try {
    const result = await getClientRewards()
    if (result.response) {
      dispatch(fetchClientRewardsSuccess(result.response))
    } else {
      throw new Error('Error fetching client rewards')
    }
  } catch (error) {
    console.error('Error fetching client rewards:', error)
    dispatch(fetchClientRewardsError(error))
  }
}

export const joinSkinChatWaitlistThunk = () => async (dispatch, getState, sdk) => {
  dispatch(joinSkinChatWaitlistRequest())

  try {
    const result = await joinSkinChatWaitlist()
    if (result.response) {
      dispatch(joinSkinChatWaitlistSuccess(result.response))
    } else {
      throw new Error('Error joining skin chat waitlist')
    }
  } catch (error) {
    console.error('Error joining skin chat waitlist:', error)
    dispatch(joinSkinChatWaitlistError(error))
  }
}

export const fetchClientBookingsAndRewardsThunk = () => async (dispatch, getState, sdk) => {
  return Promise.all([dispatch(fetchClientBookingsThunk()), dispatch(fetchClientRewardsThunk())])
}

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(fetchClientDashboardThunk()),
    dispatch(fetchPractitionerListings(0, true))
  ])
}
