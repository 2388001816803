import { getSsoEmail } from '../data'

export const getProfileImageUrl = (currentUser) => {
  if (currentUser) {
    return currentUser.profileImage?.attributes.variants['square-small'].url
  }

  return undefined
}

export const getEmail = (currentUser) => {
  if (currentUser) {
    return getSsoEmail(currentUser)
  }

  return undefined
}

export const getUserType = (currentUser) => {
  if (currentUser) {
    return currentUser.attributes?.profile?.privateData?.userType
  }

  return undefined
}

export const getIsUserProvider = (currentUser) => {
  return getUserType(currentUser) === 'provider'
}

export const getIsUserClient = (currentUser) => {
  return getUserType(currentUser) === 'client'
}

export const getUserProfile = (currentUser) => {
  if (currentUser) {
    return currentUser.attributes?.profile
  }

  return undefined
}

export const getUserFirstName = (currentUser) => {
  return getUserProfile(currentUser)?.firstName
}

export const getUserLastName = (currentUser) => {
  return getUserProfile(currentUser)?.lastName
}

export const getUserInitials = (currentUser) => {
  const firstName = getUserFirstName(currentUser)
  const lastName = getUserLastName(currentUser)

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`
  }

  return undefined
}

export const getUserFullName = (currentUser) => {
  if (currentUser) {
    const firstName = getUserFirstName(currentUser)
    const lastName = getUserLastName(currentUser)
    return `${firstName} ${lastName}`
  }

  return undefined
}
