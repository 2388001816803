import { Typography, Box } from '@material-ui/core'

import { useScheduleSectionStyles } from './hooks'
import { Schedule } from './components'

export const ScheduleSection = ({ schedule, type }) => {
  const { classes } = useScheduleSectionStyles()

  return (
    <Box className={classes.container}>
      <Typography className={classes.header}>Working hours</Typography>
      <Schedule schedule={schedule} type={type} />
    </Box>
  )
}
