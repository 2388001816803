import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  styled,
  Typography
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { InputField } from '../mui/InputField'

const StyledDialogContent = styled(DialogContent)({
  height: '450px'
})

const TreatmentPicker = (props) => {
  const { onClose, value: valueProp, open, options, title, ...other } = props
  const [value, setValue] = useState(valueProp || [])
  const [searchQuery, setSearchQuery] = useState('')
  const groupRef = useRef(null)

  useEffect(() => {
    if (!open) {
      setValue(valueProp || [])
    }
  }, [valueProp, open])

  const handleEntering = () => {
    if (groupRef.current != null) {
      groupRef.current.focus()
    }
  }

  const handleCancel = useCallback(() => {
    onClose()
  }, [onClose])

  const handleOk = useCallback(() => {
    onClose(value)
  }, [onClose, value])

  const handleChange = useCallback((event) => {
    const newValue = event.target.value
    setValue((prevValue) =>
      prevValue.includes(newValue)
        ? prevValue.filter((item) => item !== newValue)
        : [...prevValue, newValue]
    )
  }, [])

  const handleSearchChange = (value) => {
    setSearchQuery(value)
  }

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [options, searchQuery])

  const groupedOptions = useMemo(() => {
    return filteredOptions.reduce((acc, option) => {
      if (!acc[option.category]) {
        acc[option.category] = []
      }
      acc[option.category].push(option)
      return acc
    }, {})
  }, [filteredOptions])

  return (
    <Dialog
      sx={{
        '& .MuiDialog-scollPaper': { width: '80%', height: 400 }
      }}
      fullWidth={true}
      width="sm"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>{title}</DialogTitle>
      <StyledDialogContent dividers>
        <InputField
          type="text"
          name="search"
          label="Search"
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ marginBottom: 24, width: '100%' }}
        />
        {Object.keys(groupedOptions).length > 0 ? (
          Object.keys(groupedOptions).map((category) => (
            <div key={category}>
              <Typography variant="h6" gutterBottom>
                {category}
              </Typography>
              <FormGroup ref={groupRef} role="group" aria-label={category}>
                {groupedOptions[category].map((option) => (
                  <FormControlLabel
                    key={option.option}
                    control={
                      <Checkbox
                        checked={value.includes(option.option)}
                        onChange={handleChange}
                        value={option.option}
                      />
                    }
                    label={
                      <div>
                        {option.label}
                        <div style={{ fontSize: 'smaller', color: 'GrayText' }}>
                          {option.description}
                        </div>
                      </div>
                    }
                  />
                ))}
              </FormGroup>
            </div>
          ))
        ) : (
          <Typography variant="body1" align="center">
            No results found
          </Typography>
        )}
      </StyledDialogContent>
      <DialogActions style={{ marginBottom: '1rem', padding: '1rem 2rem' }}>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}

TreatmentPicker.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.array.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      option: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired
    })
  ).isRequired,
  title: PropTypes.string
}

export default TreatmentPicker
