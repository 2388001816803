import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React from 'react'
import { Button } from '../Button'

export const TermsDialog = ({ open, onClose }) => {
  const theme = useTheme()

  const closeDialog = () => {
    onClose()
  }

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth="sm">
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <DialogTitle
          style={{
            flex: 1,
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            marginRight: theme.spacing(1)
          }}
        >
          Stella Credit Terms & Conditions
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          style={{ marginRight: theme.spacing(1), flexShrink: 0 }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent dividers style={{ padding: 0, position: 'relative' }}>
        <Box display="flex" flexDirection="column" justifyContent="flex-start" padding="1.5rem">
          <Typography style={{ fontSize: '1rem', marginBottom: '1rem' }}>
            Redeemable on a minimum $200 spend for eligible products/services online or with
            participating practitioners, including skincare and general consultations. Excludes
            prescription-only treatments/products. One per customer.
          </Typography>
          <a href="https://www.seestella.com.au/pages/ts-cs-stella-directory" target="_blank">
            View Terms & Conditions
          </a>
        </Box>
      </DialogContent>
      <DialogActions style={{ marginBottom: '1rem', padding: '1rem 2rem' }}>
        <Button uiType="primary" onClick={closeDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
