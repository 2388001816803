// ================ ACTION TYPES ================ //

import { getPreviewSkinReport } from '../../../util/api'

export const FETCH_PREVIEW_REPORT_REQUEST = 'app/SkinAnalyser/FETCH_PREVIEW_REPORT_REQUEST'
export const FETCH_PREVIEW_REPORT_SUCCESS = 'app/SkinAnalyser/FETCH_PREVIEW_REPORT_SUCCESS'
export const FETCH_PREVIEW_REPORT_ERROR = 'app/SkinAnalyser/FETCH_PREVIEW_REPORT_ERROR'

export const CLEAR_PREVIEW_REPORT = 'app/SkinAnalyser/CLEAR_PREVIEW_REPORT'

// ================ REDUCER ================ //

const initialState = {
  data: {},
  fetchInProgress: false,
  fetchError: null
}

const previewSkinReportReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_PREVIEW_REPORT_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchError: null
      }
    case FETCH_PREVIEW_REPORT_SUCCESS:
      return {
        ...state,
        fetchInProgress: false,
        data: payload
      }
    case FETCH_PREVIEW_REPORT_ERROR:
      return {
        ...state,
        fetchInProgress: false,
        fetchError: payload
      }
    case CLEAR_PREVIEW_REPORT:
      return initialState
    default:
      return state
  }
}

export default previewSkinReportReducer

// ================ ACTION CREATORS ================ //

export const fetchPreviewReportRequest = () => ({
  type: FETCH_PREVIEW_REPORT_REQUEST
})

export const fetchPreviewReportSuccess = (report) => ({
  type: FETCH_PREVIEW_REPORT_SUCCESS,
  payload: report
})

export const fetchPreviewReportError = (error) => ({
  type: FETCH_PREVIEW_REPORT_ERROR,
  payload: error
})

export const clearPreviewReport = () => ({
  type: CLEAR_PREVIEW_REPORT
})

// ================ SELECTORS ================ //

export const selectPreviewSkinReport = (state) => state.SkinAnalyserReportPreviewPage

export const selectData = (state) => selectPreviewSkinReport(state).data
export const selectFetchInProgress = (state) => selectPreviewSkinReport(state).fetchInProgress
export const selectFetchError = (state) => selectPreviewSkinReport(state).fetchError

// ================ THUNKS ================ //

export const fetchPreviewSkinReport = (params) => async (dispatch, getState, sdk) => {
  dispatch(fetchPreviewReportRequest())

  try {
    const result = await getPreviewSkinReport(params)
    if (result && result.response) {
      dispatch(fetchPreviewReportSuccess(result.response))
    } else {
      throw new Error('Error fetching preview skin report')
    }
  } catch (error) {
    console.error('Error fetching preview skin report:', error)
    dispatch(fetchPreviewReportError(error))
  }
}

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const searchParams = Object.fromEntries(new URLSearchParams(search))
  const { subjectId } = searchParams
  if (subjectId) {
    return dispatch(fetchPreviewSkinReport({ subjectId }))
  }

  return Promise.resolve()
}
