import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { useState } from 'react'

import { useUseMyLocationButtonStyles } from './hooks'

import { Button } from '../../../../../Button'
import { Icon } from '../../../../../Icon'

export const UseMyLocationButton = ({ onClick }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { classes } = useUseMyLocationButtonStyles()

  const clickHandler = async () => {
    try {
      setIsLoading(true)
      await onClick()
    } catch (e) {
      console.error('Error fetching user location:', e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Button
      uiType="tertiary"
      className={classes.container}
      isDisabled={isLoading}
      startIcon={isLoading && <CircularProgress size={16} color="primary" />}
      onClick={clickHandler}
    >
      <Box className={classes.titleContainer}>
        {!isLoading && <Icon icon={faLocationArrow} className={classes.locationArrowIcon} />}
        <Typography className={classes.title}>Use my precise location</Typography>
      </Box>
    </Button>
  )
}
