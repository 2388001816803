import { Avatar as MaterialAvatar } from '@material-ui/core'

import { useAvatarStyles } from './hooks'

export const Avatar = ({ imageUrl, initials }) => {
  const { classes } = useAvatarStyles()

  if (imageUrl) {
    return <MaterialAvatar src={imageUrl} />
  } else if (initials) {
    return <MaterialAvatar className={classes.defaultAvatar}>{initials}</MaterialAvatar>
  } else {
    return <MaterialAvatar className={classes.defaultAvatar} />
  }
}
