import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { Box, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useState } from 'react'

import { CircleIcon, NoSchedulePlaceholder, ScheduleRecord } from './components'
import { useScheduleStyles } from './hooks'

import { Icon } from '../../../../../../../Icon'

import { getCurrentDayName } from '../../../../../../../../../util/new/date'
import { getStatusMessage } from '../../../../../../../utils/schedule'

const dayNamesMapping = {
  sun: 'Sunday',
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday'
}

const checkOpenStatus = (timeRange) => {
  if (timeRange.from !== undefined && timeRange.to !== undefined) {
    const now = new Date()
    const currentTime = now.getHours() * 60 + now.getMinutes() // Convert current time to minutes since midnight

    const [startHours, startMinutes] = timeRange.from.split(':').map(Number)
    const startTime = startHours * 60 + startMinutes

    const [endHours, endMinutes] = timeRange.to.split(':').map(Number)
    const endTime = endHours * 60 + endMinutes

    if (startTime < endTime) {
      // Normal case: start time is before end time
      if (currentTime >= startTime && currentTime <= endTime) {
        return 'open'
      }
    } else {
      // Overnight case: end time is after midnight
      if (currentTime >= startTime || currentTime <= endTime) {
        return 'open'
      }
    }
  }

  return 'closed'
}

const getCurrentStatus = (schedule) => {
  if (schedule) {
    const currentDay = getCurrentDayName()

    const currentDaySchedule = schedule.find((entry) => entry.day === currentDay)

    if (currentDaySchedule) {
      const timeRange = {
        from: currentDaySchedule.from,
        to: currentDaySchedule.to
      }

      const statusText = checkOpenStatus(timeRange) === 'open' ? 'Open' : 'Closed'
      const additionalStatusText = getStatusMessage(schedule)

      return { statusText, additionalStatusText }
    }

    return 'Closed'
  }

  return 'Closed'
}

export const Schedule = ({ schedule, type }) => {
  const { classes } = useScheduleStyles({ carousel: type === 'carousel' })
  const [isExpanded, setIsExpanded] = useState(false)

  const renderScheduleRecords = () => {
    return schedule.map((entry) => {
      const { day, from, to, seats } = entry
      const isActive = getCurrentDayName() === day
      const record = { day: dayNamesMapping[day], from, to, seats }

      return <ScheduleRecord record={record} isActive={isActive} key={day} />
    })
  }

  const clickHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsExpanded((prevValue) => !prevValue)
  }

  const angleIconClasses = classNames(
    classes.downAngleIcon,
    isExpanded && classes.downAngleIconRotated
  )

  const { statusText, additionalStatusText } = getCurrentStatus(schedule)

  return schedule?.length > 0 ? (
    <Box className={classes.container}>
      <Box className={classes.toggleContainer} onClick={clickHandler}>
        <Box className={classes.openClosedContainer}>
          <Typography className={classes.text}>{statusText}</Typography>
          {!isExpanded && (
            <>
              <CircleIcon />
              <Typography className={classes.text}>{additionalStatusText}</Typography>
            </>
          )}
        </Box>
        <Icon icon={faAngleDown} className={angleIconClasses} />
      </Box>
      {/* desktop schedule list */}
      <ul className={classes.desktopScheduleContainer}>{renderScheduleRecords()}</ul>{' '}
      {/* mobile schedule list */}
      {isExpanded && <ul className={classes.mobileScheduleContainer}>{renderScheduleRecords()}</ul>}
    </Box>
  ) : (
    <NoSchedulePlaceholder />
  )
}
