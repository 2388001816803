// ================ Action types ================ //

import {
  fetchPractitionerEventData,
  getHcpDashboard,
  getHcpListings,
  getHcpSkinReportPdfUrl,
  hcpAcceptStellaCredit,
  hcpEnrollStellaShop,
  hcpJoinClientCrmWaitlist
} from '../../../util/api'
import { fetchBookingConfigThunk } from '../../BookingsPage/BookingsPage.duck'

export const FETCH_DASHBOARD_DATA = 'app/PractitionerDashboardPage/FETCH_DASHBOARD_DATA'
export const FETCH_DASHBOARD_DATA_SUCCESS =
  'app/PractitionerDashboardPage/FETCH_DASHBOARD_DATA_SUCCESS'
export const FETCH_DASHBOARD_DATA_ERROR = 'app/PractitionerDashboardPage/FETCH_DASHBOARD_DATA_ERROR'

export const FETCH_SKIN_REPORT_PDF_URL_REQUEST =
  'app/PractitionerDashboardPage/FETCH_SKIN_REPORT_PDF_URL_REQUEST'
export const FETCH_SKIN_REPORT_PDF_URL_SUCCESS =
  'app/PractitionerDashboardPage/FETCH_SKIN_REPORT_PDF_URL_SUCCESS'
export const FETCH_SKIN_REPORT_PDF_URL_ERROR =
  'app/PractitionerDashboardPage/FETCH_SKIN_REPORT_PDF_URL_ERROR'

export const FETCH_BOOKINGS_DATA_REQUEST =
  'app/PractitionerDashboardPage/FETCH_BOOKINGS_DATA_REQUEST'
export const FETCH_BOOKINGS_DATA_SUCCESS =
  'app/PractitionerDashboardPage/FETCH_BOOKINGS_DATA_SUCCESS'
export const FETCH_BOOKINGS_DATA_ERROR = 'app/PractitionerDashboardPage/FETCH_BOOKINGS_DATA_ERROR'

export const FETCH_LISTINGS_DATA_REQUEST =
  'app/PractitionerDashboardPage/FETCH_LISTINGS_DATA_REQUEST'
export const FETCH_LISTINGS_DATA_SUCCESS =
  'app/PractitionerDashboardPage/FETCH_LISTINGS_DATA_SUCCESS'
export const FETCH_LISTINGS_DATA_ERROR = 'app/PractitionerDashboardPage/FETCH_LISTINGS_DATA_ERROR'

export const HCP_ENROLL_STELLA_SHOP_REQUEST =
  'app/PractitionerDashboardPage/HCP_ENROLL_STELLA_SHOP_REQUEST'
export const HCP_ENROLL_STELLA_SHOP_SUCCESS =
  'app/PractitionerDashboardPage/HCP_ENROLL_STELLA_SHOP_SUCCESS'
export const HCP_ENROLL_STELLA_SHOP_ERROR =
  'app/PractitionerDashboardPage/HCP_ENROLL_STELLA_SHOP_ERROR'

export const HCP_JOIN_CLIENT_CRM_WAITLIST_REQUEST =
  'app/PractitionerDashboardPage/HCP_JOIN_CLIENT_CRM_WAITLIST_REQUEST'
export const HCP_JOIN_CLIENT_CRM_WAITLIST_SUCCESS =
  'app/PractitionerDashboardPage/HCP_JOIN_CLIENT_CRM_WAITLIST_SUCCESS'
export const HCP_JOIN_CLIENT_CRM_WAITLIST_ERROR =
  'app/PractitionerDashboardPage/HCP_JOIN_CLIENT_CRM_WAITLIST_ERROR'

export const HCP_ACCEPT_STELLA_CREDIT_REQUEST =
  'app/PractitionerDashboardPage/HCP_ACCEPT_STELLA_CREDIT_REQUEST'
export const HCP_ACCEPT_STELLA_CREDIT_SUCCESS =
  'app/PractitionerDashboardPage/HCP_ACCEPT_STELLA_CREDIT_SUCCESS'
export const HCP_ACCEPT_STELLA_CREDIT_ERROR =
  'app/PractitionerDashboardPage/HCP_ACCEPT_STELLA_CREDIT_ERROR'

// ================ Reducer ================ //

const initialState = {
  dashboardData: {},
  dashboardDataError: null,
  dashboardDataInProgress: false,
  bookingsData: {},
  fetchBookingsDataInProgress: false,
  fetchBookingsDataError: null,
  listingsData: {},
  fetchListingsDataInProgress: false,
  fetchListingsDataError: null,
  fetchSkinReportPdfUrlInProgress: false,
  fetchSkinReportPdfUrlError: null,
  hcpEnrollStellaShopInProgress: false,
  hcpEnrollStellaShopError: null,
  hcpJoinClientCrmWaitlistInProgress: false,
  hcpJoinClientCrmWaitlistError: null,
  hcpAcceptStellaCreditInProgress: false,
  hcpAcceptStellaCreditError: null
}

const practitionerDashboardPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case FETCH_DASHBOARD_DATA:
      return {
        ...state,
        dashboardDataInProgress: true,
        dashboardDataError: null
      }
    case FETCH_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: payload,
        dashboardDataInProgress: false,
        dashboardDataError: null
      }
    case FETCH_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        dashboardDataInProgress: false,
        dashboardDataError: payload
      }

    case FETCH_SKIN_REPORT_PDF_URL_REQUEST:
      return {
        ...state,
        fetchSkinReportPdfUrlInProgress: true,
        fetchSkinReportPdfUrlError: null
      }
    case FETCH_SKIN_REPORT_PDF_URL_SUCCESS:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          skinReport: {
            ...state.dashboardData.skinReport,
            pdfUrl: payload
          }
        }
      }
    case FETCH_SKIN_REPORT_PDF_URL_ERROR:
      return {
        ...state,
        fetchSkinReportPdfUrlInProgress: false,
        fetchSkinReportPdfUrlError: payload
      }

    case FETCH_BOOKINGS_DATA_REQUEST:
      return {
        ...state,
        fetchBookingsDataInProgress: true,
        fetchBookingsDataError: null
      }
    case FETCH_BOOKINGS_DATA_SUCCESS:
      return {
        ...state,
        bookingsData: payload,
        fetchBookingsDataInProgress: false,
        fetchBookingsDataError: null
      }
    case FETCH_BOOKINGS_DATA_ERROR:
      return {
        ...state,
        fetchBookingsDataInProgress: false,
        fetchBookingsDataError: payload
      }

    case FETCH_LISTINGS_DATA_REQUEST:
      return {
        ...state,
        fetchListingsDataInProgress: true,
        fetchListingsDataError: null
      }
    case FETCH_LISTINGS_DATA_SUCCESS:
      return {
        ...state,
        listingsData: payload,
        fetchListingsDataInProgress: false,
        fetchListingsDataError: null
      }
    case FETCH_LISTINGS_DATA_ERROR:
      return {
        ...state,
        fetchListingsDataInProgress: false,
        fetchListingsDataError: payload
      }

    case HCP_ENROLL_STELLA_SHOP_REQUEST:
      return {
        ...state,
        hcpEnrollStellaShopInProgress: true,
        hcpEnrollStellaShopError: null
      }
    case HCP_ENROLL_STELLA_SHOP_SUCCESS:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          discount: payload
        },
        hcpEnrollStellaShopInProgress: false,
        hcpEnrollStellaShopError: null
      }
    case HCP_ENROLL_STELLA_SHOP_ERROR:
      return {
        ...state,
        hcpEnrollStellaShopInProgress: false,
        hcpEnrollStellaShopError: payload
      }

    case HCP_JOIN_CLIENT_CRM_WAITLIST_REQUEST:
      return {
        ...state,
        hcpJoinClientCrmWaitlistInProgress: true,
        hcpJoinClientCrmWaitlistError: null
      }
    case HCP_JOIN_CLIENT_CRM_WAITLIST_SUCCESS:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          settings: payload
        },
        hcpJoinClientCrmWaitlistInProgress: false,
        hcpJoinClientCrmWaitlistError: null
      }
    case HCP_JOIN_CLIENT_CRM_WAITLIST_ERROR:
      return {
        ...state,
        hcpJoinClientCrmWaitlistInProgress: false,
        hcpJoinClientCrmWaitlistError: payload
      }

    case HCP_ACCEPT_STELLA_CREDIT_REQUEST:
      return {
        ...state,
        hcpAcceptStellaCreditInProgress: true,
        hcpAcceptStellaCreditError: null
      }
    case HCP_ACCEPT_STELLA_CREDIT_SUCCESS:
      return {
        ...state,
        listingsData: payload,
        hcpAcceptStellaCreditInProgress: false,
        hcpAcceptStellaCreditError: null
      }
    case HCP_ACCEPT_STELLA_CREDIT_ERROR:
      return {
        ...state,
        hcpAcceptStellaCreditInProgress: false,
        hcpAcceptStellaCreditError: payload
      }
    default:
      return state
  }
}

export default practitionerDashboardPageReducer

// ================ SELECTORS ================ //

export const selectPractitionerDashboard = (state) => state.PractitionerDashboardPage

export const selectDashboardData = (state) => selectPractitionerDashboard(state).dashboardData
export const selectFetchInProgress = (state) =>
  selectPractitionerDashboard(state).dashboardDataInProgress
export const selectFetchError = (state) => selectPractitionerDashboard(state).dashboardDataError

export const selectBookingsData = (state) => selectPractitionerDashboard(state).bookingsData
export const selectFetchBookingsDataInProgress = (state) =>
  selectPractitionerDashboard(state).fetchBookingsDataInProgress
export const selectFetchBookingsDataError = (state) =>
  selectPractitionerDashboard(state).fetchBookingsDataError

export const selectListingsData = (state) => selectPractitionerDashboard(state).listingsData
export const selectFetchListingsDataInProgress = (state) =>
  selectPractitionerDashboard(state).fetchListingsDataInProgress
export const selectFetchListingsDataError = (state) =>
  selectPractitionerDashboard(state).fetchListingsDataError

export const selectHcpEnrollStellaShopInProgress = (state) =>
  selectPractitionerDashboard(state).hcpEnrollStellaShopInProgress
export const selectHcpEnrollStellaShopError = (state) =>
  selectPractitionerDashboard(state).hcpEnrollStellaShopError

export const selectHcpJoinClientCrmWaitlistInProgress = (state) =>
  selectPractitionerDashboard(state).hcpJoinClientCrmWaitlistInProgress
export const selectHcpJoinClientCrmWaitlistError = (state) =>
  selectPractitionerDashboard(state).hcpJoinClientCrmWaitlistError

export const selectHcpAcceptStellaCreditInProgress = (state) =>
  selectPractitionerDashboard(state).hcpAcceptStellaCreditInProgress
export const selectHcpAcceptStellaCreditError = (state) =>
  selectPractitionerDashboard(state).hcpAcceptStellaCreditError

// ================ Action creators ================ //

export const fetchDashboardDataRequest = () => ({
  type: FETCH_DASHBOARD_DATA
})

export const fetchDashboardDataSuccess = (response) => ({
  type: FETCH_DASHBOARD_DATA_SUCCESS,
  payload: response
})

export const fetchDashboardDataError = (e) => ({
  type: FETCH_DASHBOARD_DATA_ERROR,
  error: true,
  payload: e
})

export const fetchSkinReportPdfUrlRequest = () => ({
  type: FETCH_SKIN_REPORT_PDF_URL_REQUEST
})

export const fetchSkinReportPdfUrlSuccess = (response) => ({
  type: FETCH_SKIN_REPORT_PDF_URL_SUCCESS,
  payload: response
})

export const fetchSkinReportPdfUrlError = (e) => ({
  type: FETCH_SKIN_REPORT_PDF_URL_ERROR,
  error: true,
  payload: e
})

export const fetchBookingsDataRequest = () => ({
  type: FETCH_BOOKINGS_DATA_REQUEST
})

export const fetchBookingsDataSuccess = (response) => ({
  type: FETCH_BOOKINGS_DATA_SUCCESS,
  payload: response
})

export const fetchBookingsDataError = (e) => ({
  type: FETCH_BOOKINGS_DATA_ERROR,
  error: true,
  payload: e
})

export const fetchListingsDataRequest = () => ({
  type: FETCH_LISTINGS_DATA_REQUEST
})

export const fetchListingsDataSuccess = (response) => ({
  type: FETCH_LISTINGS_DATA_SUCCESS,
  payload: response
})

export const fetchListingsDataError = (e) => ({
  type: FETCH_LISTINGS_DATA_ERROR,
  error: true,
  payload: e
})

export const hcpEnrollStellaShopRequest = () => ({
  type: HCP_ENROLL_STELLA_SHOP_REQUEST
})

export const hcpEnrollStellaShopSuccess = (response) => ({
  type: HCP_ENROLL_STELLA_SHOP_SUCCESS,
  payload: response
})

export const hcpEnrollStellaShopError = (e) => ({
  type: HCP_ENROLL_STELLA_SHOP_ERROR,
  error: true,
  payload: e
})

export const hcpJoinClientCrmWaitlistRequest = () => ({
  type: HCP_JOIN_CLIENT_CRM_WAITLIST_REQUEST
})

export const hcpJoinClientCrmWaitlistSuccess = (response) => ({
  type: HCP_JOIN_CLIENT_CRM_WAITLIST_SUCCESS,
  payload: response
})

export const hcpJoinClientCrmWaitlistError = (e) => ({
  type: HCP_JOIN_CLIENT_CRM_WAITLIST_ERROR,
  error: true,
  payload: e
})

export const hcpAcceptStellaCreditRequest = () => ({
  type: HCP_ACCEPT_STELLA_CREDIT_REQUEST
})

export const hcpAcceptStellaCreditSuccess = (response) => ({
  type: HCP_ACCEPT_STELLA_CREDIT_SUCCESS,
  payload: response
})

export const hcpAcceptStellaCreditError = (e) => ({
  type: HCP_ACCEPT_STELLA_CREDIT_ERROR,
  error: true,
  payload: e
})

// ================ Thunks ================ //

export const fetchDashboardDataThunk = () => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchDashboardDataRequest())
    const result = await getHcpDashboard()
    if (result.response) {
      dispatch(fetchDashboardDataSuccess(result.response))
    } else {
      throw new Error('Failed to fetch dashboard data')
    }
  } catch (err) {
    console.error('Error fetching dashboard data', err)
    dispatch(fetchDashboardDataError(err))
  }
}

export const fetchSkinReportPdfUrlThunk = () => async (dispatch, getState, sdk) => {
  dispatch(fetchSkinReportPdfUrlRequest())
  const result = await getHcpSkinReportPdfUrl()
  if (result.response) {
    dispatch(fetchSkinReportPdfUrlSuccess(result.response))
  } else {
    throw new Error('Failed to fetch skin report pdf url')
  }
}

export const fetchBookingsDataThunk = () => async (dispatch, getState, sdk) => {
  dispatch(fetchBookingsDataRequest())
  const result = await fetchPractitionerEventData({
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  })
  if (result.response) {
    dispatch(fetchBookingsDataSuccess(result.response))
  } else {
    throw new Error('Failed to fetch bookings data')
  }
}

export const fetchListingsDataThunk = () => async (dispatch, getState, sdk) => {
  dispatch(fetchListingsDataRequest())
  const result = await getHcpListings()
  if (result.response) {
    dispatch(fetchListingsDataSuccess(result.response))
  } else {
    throw new Error('Failed to fetch listings data')
  }
}

export const hcpEnrollStellaShopThunk = () => async (dispatch, getState, sdk) => {
  dispatch(hcpEnrollStellaShopRequest())
  const result = await hcpEnrollStellaShop()
  if (result.response) {
    dispatch(hcpEnrollStellaShopSuccess(result.response))
  } else {
    throw new Error('Failed to enroll hcp in stella shop')
  }
}

export const hcpJoinClientCrmWaitlistThunk = () => async (dispatch, getState, sdk) => {
  dispatch(hcpJoinClientCrmWaitlistRequest())
  const result = await hcpJoinClientCrmWaitlist()
  if (result.response) {
    dispatch(hcpJoinClientCrmWaitlistSuccess(result.response))
  } else {
    throw new Error('Failed to join hcp in client crm waitlist')
  }
}

export const hcpAcceptStellaCreditThunk = () => async (dispatch, getState, sdk) => {
  dispatch(hcpAcceptStellaCreditRequest())
  const result = await hcpAcceptStellaCredit()
  if (result.response) {
    dispatch(hcpAcceptStellaCreditSuccess(result.response))
  } else {
    throw new Error('Failed to accept stella credit')
  }
}

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(fetchBookingConfigThunk()),
    dispatch(fetchDashboardDataThunk()),
    dispatch(fetchListingsDataThunk())
  ])
}
