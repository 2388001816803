import {
  faCalendar,
  faComments,
  faFlag,
  faHeadset,
  faHome,
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons'
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import { useEffect, useState } from 'react'

import { useBottomNavigationStyles } from './hooks'

import { getIsUserProvider } from '../../../../../util/new/currentUser'
import NamedRedirect from '../../../../NamedRedirect/NamedRedirect'
import { Icon } from '../../../Icon'

export const BottomMobileNavigation = ({ isAuthenticated, currentUser }) => {
  const [value, setValue] = useState(null)
  const [redirect, setRedirect] = useState({ redirect: false, name: '' })
  const { joinedContainerClasses, joinedLabelClasses } = useBottomNavigationStyles()

  const isProvider = getIsUserProvider(currentUser)

  useEffect(() => {
    const path = window.location.pathname

    if (isProvider) {
      // Logged in as provider
      if (path.endsWith('/s')) {
        setValue(0)
      } else if (path.includes('/practitioner-dashboard')) {
        setValue(1)
      } else if (path.includes('/bookings')) {
        setValue(2)
      } else if (path.includes('/requests')) {
        setValue(3)
      }
    } else if (isAuthenticated && !isProvider) {
      // Logged in as client
      if (path.endsWith('/s')) {
        setValue(0)
      } else if (path.includes('/client-dashboard')) {
        setValue(1)
      } else if (path.includes('/post-goal')) {
        setValue(2)
      } else if (path.includes('/online-concierge')) {
        setValue(3)
      }
    } else {
      // Logged out
      if (path.endsWith('/s')) {
        setValue(0)
      } else if (path.includes('/post-goal')) {
        setValue(1)
      } else if (path.includes('/online-concierge')) {
        setValue(2)
      }
    }
  }, [])

  const renderNavigationActions = () => {
    if (isAuthenticated && isProvider) {
      return [
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'SearchPage' })}
          key="Search"
          label="Search"
          icon={<Icon icon={faMagnifyingGlass} />}
          className={joinedLabelClasses}
        />,
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'PractitionerDashboardPage' })}
          key="Dashboard"
          label="Dashboard"
          icon={<Icon icon={faHome} />}
          className={joinedLabelClasses}
        />,
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'BookingsPage' })}
          key="Bookings"
          label="Bookings"
          icon={<Icon icon={faCalendar} />}
          className={joinedLabelClasses}
        />,
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'ConsultRequestsPage' })}
          key="Requests"
          label="Requests"
          icon={<Icon icon={faFlag} />}
          className={joinedLabelClasses}
        />
      ]
    } else if (isAuthenticated && !isProvider) {
      return [
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'SearchPage' })}
          key="Search"
          label="Search"
          icon={<Icon icon={faMagnifyingGlass} />}
          className={joinedLabelClasses}
        />,
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'ClientDashboardPage' })}
          key="Dashboard"
          label="Dashboard"
          icon={<Icon icon={faHome} />}
          className={joinedLabelClasses}
        />,
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'PostConcernPage' })}
          key="Post a goal"
          label="Post a goal"
          icon={<Icon icon={faComments} />}
          className={joinedLabelClasses}
        />,
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'OnlineConciergePage' })}
          key="Online concierge"
          label="Concierge"
          icon={<Icon icon={faHeadset} />}
          className={joinedLabelClasses}
        />
      ]
    }

    // Default
    return [
      <BottomNavigationAction
        onClick={() => setRedirect({ redirect: true, name: 'SearchPage' })}
        key="Search"
        label="Search"
        icon={<Icon icon={faMagnifyingGlass} />}
        className={joinedLabelClasses}
      />,
      <BottomNavigationAction
        onClick={() => setRedirect({ redirect: true, name: 'PostConcernPage' })}
        key="Post a goal"
        label="Post a goal"
        icon={<Icon icon={faComments} />}
        className={joinedLabelClasses}
      />,
      <BottomNavigationAction
        onClick={() => setRedirect({ redirect: true, name: 'OnlineConciergePage' })}
        key="Online concierge"
        label="Concierge"
        icon={<Icon icon={faHeadset} />}
        className={joinedLabelClasses}
      />
    ]
  }

  return (
    <BottomNavigation value={value} className={joinedContainerClasses} showLabels>
      {renderNavigationActions()}
      {redirect.redirect && <NamedRedirect name={redirect.name} />}
    </BottomNavigation>
  )
}
