import { faGift } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { signUpUrl } from '../../../util/api'
import { getIsUserProvider } from '../../../util/new/currentUser'
import { Button } from '../Button'
import { TermsDialog } from '../TermsDialog/TermsDialog'

const CreditCardContent = ({
  isLoggedIn,
  isSmall,
  onButtonClick,
  signUpLoading,
  location,
  displayName
}) => {
  const [openTerms, setOpenTerms] = useState(false)

  const buttonProps = isLoggedIn
    ? {
        onClick: onButtonClick,
        children: 'View $50 Stella Credit'
      }
    : {
        // href: signUpUrl(location.pathname + location.search),
        onClick: onButtonClick,
        isLoading: signUpLoading,
        children: isSmall ? 'Sign Up & Get $50 Stella Credit' : 'Get $50 Stella Credit'
      }

  return isSmall ? (
    <Button uiType={'primary'} {...buttonProps} />
  ) : (
    <>
      <Paper style={{ padding: 14, backgroundColor: '#EBF6F8' }} elevation={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box marginRight={2}>
            <FontAwesomeIcon icon={faGift} size="2x" style={{ color: '#006D77' }} />
          </Box>
          <Box flexGrow={1} marginRight={2}>
            <Typography variant={'body1'} style={{ color: '#006D77' }}>
              {isLoggedIn ? 'View Your $50 Stella Credit' : 'Sign Up & Get $50 Stella Credit'}
            </Typography>
            {displayName ? (
              <Typography variant="caption" style={{ color: '#006D77' }}>
                Use Stella Credit online or in clinic when booking with {displayName}.
              </Typography>
            ) : (
              <Typography variant="caption" style={{ color: '#006D77' }}>
                Use Stella Credit online or in clinic with participating experts.
                <br />
                Look for the{' '}
                <b style={{ color: 'var(--marketplaceColor)' }}>Accepts Stella Credit</b> badge on
                their profile.
              </Typography>
            )}

            <Typography style={{ fontSize: '0.75rem', color: '#006D77' }}>
              Terms & Conditions apply. <a onClick={() => setOpenTerms(true)}>View terms.</a>
            </Typography>
          </Box>
          <Button uiType="primary" {...buttonProps} />
        </Box>
      </Paper>
      <TermsDialog open={openTerms} onClose={() => setOpenTerms(false)} />
    </>
  )
}

export const GetCreditCard = ({ currentUser, listing }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const location = useLocation()

  const [signUpLoading, setSignUpLoading] = useState(false)
  const [showVoucher, setShowVoucher] = useState(false)
  const [showFillout, setShowFillout] = useState(false)

  const isLoggedIn = useMemo(() => !!currentUser, [currentUser])
  const isProvider = useMemo(() => getIsUserProvider(currentUser), [currentUser])

  if (isProvider || isLoggedIn) return null

  const handleButtonClick = () => {
    setSignUpLoading(true)
    window.location.href = signUpUrl(location.pathname + location.search)
  }

  return (
    <>
      <CreditCardContent
        isLoggedIn={isLoggedIn}
        isSmall={isSmall}
        onButtonClick={handleButtonClick}
        signUpLoading={signUpLoading}
        location={location}
        displayName={listing?.displayName}
      />
      {/* <VoucherDialog
        showVoucher={showVoucher}
        setShowVoucher={setShowVoucher}
        currentUser={currentUser}
        displayName={listing?.displayName}
      /> */}

      {/* {showFillout && (
        <StellaFilloutPopupEmbed filloutId="427A6zHRf7us" onClose={() => setShowFillout(false)} />
      )} */}
    </>
  )
}
