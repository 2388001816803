export const typography = {
  fontSize: 16,
  fontFamily: 'Inter, Arial, sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,

  primaryHeading: {
    fontFamily: '"Kamerik 105", Inter, Arial, sans-serif',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 1.2,
    color: '#ff606d'
  }
}
