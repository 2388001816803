import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      top: 0,
      left: 0,
      width: '100%',
      height: '64px',
      padding: '0 24px',
      backgroundColor: palette.background.default,
      boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.1)',
      zIndex: 1000
    },
    innerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      maxWidth: '1480px',
      height: '64px'
    },
    menuItem: {
      margin: '0 4px'
    },
    menuItemContainer: {
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    logo: {
      width: '92px',
      height: '24px'
    },
    authButtonsContainer: {
      display: 'flex',
      gap: '16px'
    },
    signUpButton: {
      fontWeight: 500,
      fontFamily: 'Inter',
      backgroundColor: palette.primary.main,
      color: palette.common.white
    },
    signInButton: {
      fontWeight: 500,
      fontFamily: 'Inter',
      color: palette.button.text.tertiary
    }
  }
})

export const useTopbarStyles = () => {
  const classes = useStyles()

  return { classes }
}
