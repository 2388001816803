import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { breakpoints, palette } = theme

  return {
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      gap: '20px',
      paddingBottom: ({ carousel }) => (carousel ? '0px' : '32px'),
      borderBottom: ({ carousel }) => (carousel ? 'none' : `1px solid ${palette.grey['200']}`),
      [breakpoints.down('sm')]: {
        paddingBottom: ({ carousel }) => (carousel ? '0px' : '24px')
      }
    },
    mainContentContainer: ({ carousel }) => ({
      display: 'flex',
      gap: '20px',
      width: '100%',
      maxWidth: '1490px',
      '&:hover': {
        cursor: 'pointer'
      },
      [breakpoints.down('md')]: {
        flexDirection: 'column'
      },
      ...(carousel && {
        flexDirection: 'column'
      })
    }),
    practitionerInfoContainer: ({ carousel }) => ({
      display: 'flex',
      flexBasis: '63%',
      flexDirection: 'column',
      gap: '16px',
      [breakpoints.down('md')]: {
        flexBasis: '100%'
      },
      ...(carousel && {
        flexBasis: '100%'
      })
    }),
    topSectionContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    generalInfoContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    detailedInfoContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      gap: '12px',
      [breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    imagesAndScheduleContainer: ({ carousel }) => ({
      display: 'flex',
      gap: '20px',
      flexGrow: 1,
      [breakpoints.down('xs')]: {
        flexDirection: 'column'
      },
      ...(carousel && {
        flexDirection: 'column'
      })
    }),
    desktopBookConsultationButtonContainer: ({ carousel }) => ({
      display: 'flex',
      [breakpoints.down('xs')]: {
        display: 'none'
      },
      ...(carousel && {
        display: 'none'
      })
    }),
    mobileBookConsultationButtonContainer: ({ carousel }) => ({
      display: 'none',
      [breakpoints.down('xs')]: {
        display: 'flex',
        ...(carousel && {
          display: 'none'
        })
      }
    })
  }
})

export const usePractitionerListingStyles = ({ carousel = false }) => {
  const classes = useStyles({ carousel })
  return { classes }
}
