import { Box, CircularProgress } from '@material-ui/core'
import { FavoriteBorderOutlined, FavoriteOutlined } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { useFavoritesButtonStyles } from './hooks'

import { useRouteConfiguration } from '../../../../../../../context/routeConfigurationContext'
import { isAuthenticatedSelector } from '../../../../../../../ducks/auth.duck'
import {
  selectFavouriteListingIds,
  selectToggleFavoriteInProgress,
  selectToggleFavoriteListingId,
  toggleFavoriteListing
} from '../../../../../../../ducks/user.duck'
import {
  renderErrorToast,
  renderInfoToast,
  renderSuccessToast
} from '../../../../../../../util/new/toast'
import { createResourceLocatorString } from '../../../../../../../util/routes'

export const FavoritesButton = ({ isActive, listingId }) => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(isAuthenticatedSelector)
  const toggleFavoriteInProgress = useSelector(selectToggleFavoriteInProgress)
  const togglingFavoriteListingId = useSelector(selectToggleFavoriteListingId)
  const favouriteListingIds = useSelector(selectFavouriteListingIds)
  const history = useHistory()
  const location = useLocation()
  const routeConfiguration = useRouteConfiguration()
  const { classes } = useFavoritesButtonStyles()

  const mouseUpHandler = (event) => {
    event.currentTarget.blur()
  }

  const clickHandler = async (event) => {
    event.stopPropagation()
    event.preventDefault()

    if (isAuthenticated) {
      dispatch(toggleFavoriteListing(listingId))
        .then(() => {
          favouriteListingIds.includes(listingId)
            ? renderInfoToast('Removed from favourites')
            : renderSuccessToast('Added to favourites')
        })
        .catch(() => {
          const message = favouriteListingIds.includes(listingId)
            ? 'Error removing from favourites'
            : 'Error adding to favourites'
          renderErrorToast(message)
        })
    } else {
      history.push(createResourceLocatorString('LoginPage', routeConfiguration, {}, {}), {
        from: location.pathname + location.search
      })
    }
  }

  if (toggleFavoriteInProgress && togglingFavoriteListingId === listingId) {
    return (
      <Box className={classes.addFavoritesButtonContainer}>
        <CircularProgress size={18} />
      </Box>
    )
  }

  return (
    <Box
      className={classes.addFavoritesButtonContainer}
      onClick={clickHandler}
      tabIndex={0}
      onMouseUp={mouseUpHandler}
    >
      {isActive ? (
        <FavoriteOutlined className={classes.addFavoritesButton} />
      ) : (
        <FavoriteBorderOutlined className={classes.addFavoritesButton} />
      )}
    </Box>
  )
}
