import { Bounce, toast } from 'react-toastify'

const toastProps = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Bounce,
  theme: 'colored'
}

export const renderSuccessToast = (text) => {
  toast.success(text, toastProps)
}

export const renderErrorToast = (text) => {
  toast.error(text, toastProps)
}

export const renderWarningToast = (text) => {
  toast.warning(text, toastProps)
}

export const renderInfoToast = (text) => {
  toast.info(text, toastProps)
}

export const renderStellaToast = (text, theme) => {
  toast.info(text, {
    ...toastProps,
    style: { backgroundColor: theme ? theme.palette.primary.main : 'white' }
  })
}
