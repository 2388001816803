import { useBookConsultationButtonStyles } from './hooks'

import { AnalyticsEvent, trackEvent } from '../../../../../../../analytics/handlers'
import { Button } from '../../../../../Button'

export const BookConsultationButton = ({ href, listingId }) => {
  const { classes } = useBookConsultationButtonStyles()

  const clickHandler = (e) => {
    trackEvent(AnalyticsEvent.bookingRequestTapped, { id: listingId })
    e.stopPropagation()
  }

  return (
    <Button className={classes.container} href={href} onClick={clickHandler}>
      Book a consultation
    </Button>
  )
}
