import { Box } from '@material-ui/core'
import { useEffect, useState } from 'react'

import { BottomMobileNavigation, MenuItem, UserMenuDropdown } from './components'
import { useTopbarStyles } from './hooks'

import { Button } from '../Button'

import { useLocation } from 'react-router-dom'
import LOGO from '../../../assets/stella-logo.svg'
import { loginUrl, signUpUrl } from '../../../util/api'
import { getIsUserProvider } from '../../../util/new/currentUser'

export const Topbar = ({
  authInProgress,
  currentUser,
  isAuthenticated,
  onLogout,
  hideNav,
  from
}) => {
  const [activeMenuItem, setActiveMenuItem] = useState('')
  const { classes } = useTopbarStyles()

  const [isSignInLoading, setIsSignInLoading] = useState(false)
  const [isSignUpLoading, setIsSignUpLoading] = useState(false)

  const location = useLocation()

  const isProvider = getIsUserProvider(currentUser)

  useEffect(() => {
    const path = window.location.pathname

    if (path.endsWith('/s')) {
      setActiveMenuItem('/s')
    } else if (path.includes('/online-concierge')) {
      setActiveMenuItem('/online-concierge')
    } else if (path.includes('/post-goal')) {
      setActiveMenuItem('/post-goal')
    } else if (path.includes('/practitioner-dashboard')) {
      setActiveMenuItem('/practitioner-dashboard')
    } else if (path.includes('/requests')) {
      setActiveMenuItem('/requests')
    } else if (path.includes('/bookings')) {
      setActiveMenuItem('/bookings')
    } else if (path.includes('/client-dashboard')) {
      setActiveMenuItem('/client-dashboard')
    }
  }, [])

  const renderMenuItems = () => {
    if (isAuthenticated && isProvider) {
      return (
        <Box className={classes.menuItemContainer}>
          <MenuItem
            name="SearchPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/s'}
          >
            Find an expert
          </MenuItem>
          <MenuItem
            name="PractitionerDashboardPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/practitioner-dashboard'}
          >
            Dashboard
          </MenuItem>
          <MenuItem
            name="BookingsPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/bookings'}
          >
            Bookings
          </MenuItem>
          <MenuItem
            name="ConsultRequestsPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/requests'}
          >
            Requests
          </MenuItem>
        </Box>
      )
    }

    if (isAuthenticated && !isProvider) {
      return (
        <Box className={classes.menuItemContainer}>
          <MenuItem
            name="SearchPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/s'}
          >
            Find an expert
          </MenuItem>
          <MenuItem
            name="ClientDashboardPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/client-dashboard'}
          >
            Dashboard
          </MenuItem>
          <MenuItem
            name="PostConcernPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/post-goal'}
          >
            Post a goal
          </MenuItem>
          <MenuItem
            name="OnlineConciergePage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/online-concierge'}
          >
            Online concierge
          </MenuItem>
        </Box>
      )
    }

    return (
      <Box className={classes.menuItemContainer}>
        <MenuItem name="SearchPage" className={classes.menuItem} isActive={activeMenuItem === '/s'}>
          Find an expert
        </MenuItem>
        <MenuItem
          name="PostConcernPage"
          className={classes.menuItem}
          isActive={activeMenuItem === '/post-goal'}
        >
          Post a goal
        </MenuItem>
        <MenuItem
          name="OnlineConciergePage"
          className={classes.menuItem}
          isActive={activeMenuItem === '/online-concierge'}
        >
          Online concierge
        </MenuItem>
      </Box>
    )
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.innerContainer}>
          {hideNav ? (
            <>
              <img src={LOGO} alt="stella-logo" className={classes.logo} />
              {from && (
                <Box>
                  <Button uiType="tertiary" size="small" href={`/${from}`}>
                    Dashboard
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <a href="https://www.seestella.com.au/">
              <img src={LOGO} alt="stella-logo" className={classes.logo} />
            </a>
          )}
          {!hideNav && (
            <>
              {renderMenuItems()}
              {!authInProgress && isAuthenticated ? (
                <UserMenuDropdown currentUser={currentUser} onLogout={onLogout} />
              ) : (
                <Box className={classes.authButtonsContainer}>
                  <Button
                    uiType="tertiary"
                    onClick={() => setIsSignInLoading(true)}
                    href={loginUrl(location.pathname + location.search)}
                    isLoading={isSignInLoading}
                    size="large"
                    className={classes.signInButton}
                  >
                    Sign in
                  </Button>
                  <Button
                    uiType="primary"
                    onClick={() => setIsSignUpLoading(true)}
                    href={signUpUrl(location.pathname + location.search)}
                    isLoading={isSignUpLoading}
                    size="large"
                    className={classes.signUpButton}
                  >
                    Sign up
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      {!hideNav && (
        <BottomMobileNavigation isAuthenticated={isAuthenticated} currentUser={currentUser} />
      )}
    </>
  )
}
