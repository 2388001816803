import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette, breakpoints } = theme

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px'
    },
    toggleContainer: ({ carousel }) => ({
      display: 'none',
      [breakpoints.down('xs')]: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      },
      ...(carousel && {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      })
    }),
    downAngleIcon: {
      width: '18px',
      height: '18px',
      color: palette.grey['400'],
      transition: 'transform 0.3s ease',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    downAngleIconRotated: {
      transform: 'rotate(180deg)'
    },
    openClosedContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px'
    },
    text: {
      fontFamily: 'Inter',
      fontSize: '14px',
      color: palette.blue['800'],
      padding: 0,
      letterSpacing: 0
    },
    desktopScheduleContainer: ({ carousel }) => ({
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      margin: 0,
      [breakpoints.down('xs')]: {
        display: 'none'
      },
      ...(carousel && {
        display: 'none'
      })
    }),
    mobileScheduleContainer: ({ carousel }) => ({
      display: 'none',
      [breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        margin: 0
      },
      ...(carousel && {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        margin: 0
      })
    })
  }
})

export const useScheduleStyles = ({ carousel = false }) => {
  const classes = useStyles({ carousel })
  return { classes }
}
